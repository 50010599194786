<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>

      <dx-item
        v-if="title"
        location="before"
        css-class="header-title dx-toolbar-label"
      >
      <img 
        :src="logoUrl"
        style="height: 47px; cursor: pointer; position: absolute; top: -10px;" 
        @click="goToHome"
      />
      </dx-item>
      <DxItem
        location="center"
        v-if="!isMobile"
      >
        <div v-if="!companyConfig">
          <img :src="require('@/assets/Infitech_logo.png')" style="height: 30px;" >
        </div>
        <div v-else>
          {{ appTitle }}
        </div>
      </DxItem>
      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
      <template #default>
        <div>
          
          <dx-button
            class="user-button authorization"
            height="100%"
            styling-mode="text"
          >
            <div class="user-icon">{{ name }}</div>
            <user-panel  :email="email" :menu-items="userMenuItems" menu-mode="context" />
          </dx-button>
        </div>
      </template>
      </dx-item>
      
      <template #menuUserItem>
        <user-panel
          :email="email"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { ref, defineProps, defineExpose } from 'vue';
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { useAccountStore } from "@/stores/account";
import UserPanel from "@/components/user-panel.vue";
import { $t } from '@/services/localization/LocalizationApp';
import AuthManager from '@/AuthManager';
import connConfig from '@/services/connConfig';

defineProps({
  menuToggleEnabled: Boolean,
  title: String,
  toggleMenuFunc: Function,
  logOutFunc: Function
});


const router = useRouter();
const route = useRoute();
const accountStore = useAccountStore();
const user = accountStore.$state.user;

const valueMode = ref(false);
const email = ref("");
const name = ref("");
const isMobile = ref(window.innerWidth <= 600);

// config company
const logoUrl = ref(require("@/assets/Gecko_appM.png"));
const companyConfig = ref(false)
const appTitle = ref("");


if (user && user.email && user.name && user.lastname) {
  email.value = user.email;
  name.value = getInitials(`${user.name} ${user.lastname}`);
}
if(user && user.email && user.name){
  email.value = user.email;
  name.value = getInitials(user.name);
}

if (user && user.company && user.company.companySettings) {
  const token = AuthManager.getSession()?.token;
  const appConfig = connConfig.connData(null, token);

  logoUrl.value = (new URL(user.company.companySettings.appearance.logoUrl, appConfig.rootBaseURL)).href;
  
  companyConfig.value = true;
  appTitle.value = user.company.companySettings.appearance.appTitle;
}

const userMenuItems = [
  {
    text: $t("sessionmenu_profile"),
    icon: "user",
    onClick: onProfileClick
  },
  {
    text: $t("sessionmenu_logout"),
    icon: "runner",
    onClick: onLogoutClick
  }
];

function getInitials(namelastname: string): string {
  const name = namelastname.trim();
  const words = name.split(' ');
  let initials = words[0].charAt(0);
  if (words.length > 1) {
    initials += words[1].charAt(0);
  }
  return initials.toUpperCase();
}

  
async function onLogoutClick(): Promise<void> {
  await accountStore.logOut();
  router.push({
    path: "/login-form",
  });
}

function onProfileClick(): void {
  router.push({
    path: "/profile",
  });
}

function goToHome() {
  router.push(
    {
      path: "/sites",
      query: { redirect: route.path }
    }
  );
}

function onChangeLanguage(value: any): void {
  console.log("onChangeLanguage", value.itemData.text)
}

defineExpose ({
  email,
  valueMode,
  onChangeLanguage,
  isMobile,
  goToHome,
})
</script>

<style lang="scss">
@import "@/themes/generated/variables.base.scss";
@import "@/dx-styles.scss";
.dx-toolbar .dx-toolbar-item:last-child {
  font-size: 1.3em;
}
.user-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #a5a3a3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
}
.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0px
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
.dx-switch-container{
  height: 20px;
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid;

  .dx-switch-handle::before{
    height: 16px;
    border-radius: 40px;
  }
}
.dx-menu-base .dx-menu-item .dx-menu-item-content .dx-menu-item-popout-container {
  display: none;
}
@media(max-width: 600){
 .user-icon {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
}
</style>
