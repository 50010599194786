

let apiURL = 'http://localhost/dirservice'

// Allow to override the API URL with an environment variable. To point to test or production backend servers
if (process.env.VUE_APP_API_URL) {
  apiURL = process.env.VUE_APP_API_URL
}

export default {
  apiURL: () => apiURL,
  appURL: () => {
    const url = new URL(apiURL)
    const rootUrl = `${url.protocol}//${url.hostname}${url.port ? ':' + url.port : ''}`
    return rootUrl
  },
  timeout: 1000,

  /**
   * Generates the authorization header object.
   * @param accessToken - The access token for authentication.
   * @returns The authorization header object.
   */
  authHeader (accessToken?: string) {
    if (accessToken) {
      // for Node.js Express back-end
      return { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json'
      };
  } else {
      return { 
        'Content-type': 'application/json'
      };
  }
  },

  connData(extraConfig?: any, accessToken?: string): any {
      return {...{
          baseURL: this.apiURL(),
          rootBaseURL: this.appURL(),
          headers: this.authHeader(accessToken),
      }, ...extraConfig}
  }
};

