import { ref } from 'vue';

const alertType = ref('');
const isVisible = ref(false);
const message = ref('');

/**
 * Handles the error by displaying an error message and setting the alert type.
 * @param errorMsg - The error message to display.
 * @param errorType - The type of error.
 */
function handleError(errorMsg: string, errorType: string): void {
  message.value = errorMsg;
  alertType.value = `alert-${errorType}`;
  isVisible.value = true;
}

export default ({
  alertType,
  isVisible,
  message,
  handleError,
});