import { locale, loadMessages, formatMessage } from "devextreme/localization";
import { useAccountStore } from "@/stores/account";

// Import the Devextreme dictionaries
import esDevExtremeMessages from "devextreme/localization/messages/es.json";
import enDevExtremeMessages from "devextreme/localization/messages/en.json";
import frDevExtremeMessages from "devextreme/localization/messages/fr.json";

// Import the app dictionaries
import esAppMessages from '@/localization/messages/es.json';
import enAppMessages from '@/localization/messages/en.json';
import frAppMessages from '@/localization/messages/fr.json';
import zhAppMessages from '@/localization/messages/zh.json';
import AuthManager from "@/AuthManager";
import { GeckoUser } from "@/types/userSessionTypes";



export interface ISupportedLanguage {
  key: string
  code: string
  name: string
  dictionaries: object[] // List of dictionary objects to load. Must be imported previously
}

/**
 * Class to manage the localization of the app
 * initialize the localization of the app
 * Load the dictionaries of the languages
 * export the translate function
 */
export class LocalizationApp {
  private static readonly DEFAULT_LANG = 'es'

  private static readonly LANGUAGES: ISupportedLanguage[] = [
    { key: 'es', code:'es_ES', name: 'Spanish' , dictionaries: [esDevExtremeMessages, esAppMessages] },
    { key: 'fr', code:'fr_FR', name: 'French' , dictionaries: [frDevExtremeMessages, frAppMessages] },
    { key: 'en', code:'en_EN', name: 'English'  , dictionaries: [enDevExtremeMessages, enAppMessages] },
    { key: 'zh', code:'zh_CN', name: 'Chinese'  , dictionaries: [enDevExtremeMessages, zhAppMessages] }
  ]

  public static async init(): Promise<void> {

    const language = LocalizationApp.getCurrentLanguage()
    const langCode = language.key

    await LocalizationApp.loadLangDictionaries(language)
    await LocalizationApp.activateLanguage(langCode)
  }

  private static async loadLangDictionaries(language: ISupportedLanguage): Promise<void> {
    // Load the DevExtreme dictionaries
    language.dictionaries.forEach(async dictionary => {
      loadMessages(dictionary)  
    })
    
  }

  private static activateLanguage(langCode: string): void {
    locale(langCode)
  }

  /**
   * Get the default language
   * @returns 
   */
  public static getDefaultLang(): ISupportedLanguage {
    return  LocalizationApp.getLanguage(LocalizationApp.DEFAULT_LANG)
  }
  
  /**
   * Get the language object by the language code
   * @param languageCode 
   * @returns 
   */
  public static getLanguage(languageCode: string): ISupportedLanguage {
    const language = LocalizationApp.LANGUAGES.find(lang => lang.key === languageCode)
    if (!language) {
      throw new Error('language not found : ' + languageCode)
    }

    return language
  }

  /**
   * Get a list of supported languages
   * @returns a list of objects 
   */
  public static getListSupportedLanguages(): ISupportedLanguage[] {
    return LocalizationApp.LANGUAGES
  }

  /**
   * Translate a key to the current language
   * @param key 
   * @param args 
   * @returns 
   */
  public static translate(key: string, ...args: any[]): string {
    const translatedMessage = formatMessage(key, ...args)
    return translatedMessage
  }

  public static getCurrentLanguage(): ISupportedLanguage {
    const getCurrentLangCode = LocalizationApp.getCurrentLangCode()
    return LocalizationApp.getLanguage(getCurrentLangCode)
  }

  /**
   * Get the current language code, registered in the app
   * @returns
   */
  private static getCurrentLangCode(): string {
    const user = LocalizationApp.getCurrentUser()
    let langCode = LocalizationApp.DEFAULT_LANG
    if (user) {
      const userLanguage = user.language?.split('_')[0] || LocalizationApp.DEFAULT_LANG
      langCode = LocalizationApp.getLanguage(userLanguage).key
    } else {
      langCode =  LocalizationApp.getBrowserLanguage() || LocalizationApp.DEFAULT_LANG
    }

    return langCode
  } 

  /**
   * Get the user that is currently logged in
   * Returns null if no user is logged in
   * @returns 
   */
  private static getCurrentUser(): GeckoUser | null {
    const user = AuthManager.getUser()
    // If user logged out, remain a default blank user, with a default language. We don't want that, we return null
    if (user.id === '' || user.id === undefined || user.id === null) {
      return null
    }
    
    return user
  }

  /**
   * Get the language of the browser
   * Null if not found / detected
   * @returns 
   */
  private static getBrowserLanguage(): string | null {
    const nav = window.navigator
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']
    let language = null

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      for (let i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i];
        if (language && language.length) {
          return language.split('-')[0]
        }
      }
    }

    // support for other well known properties in browsers
    for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
      //@ts-ignore
      language = nav[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language.split('-')[0]
      }
    }

    return null
  }

  
}

/**
 * Alias function to the translate method
 * We use this function name to be more similar to the VueI18n
 * @param key 
 * @param args 
 * @returns 
 */
export function $t(key: string, ...args: any[]): string {
  let translatedMessage = LocalizationApp.translate(key, ...args)
  //If not found the translation, return the key and log a warning
  if (translatedMessage === '') {
    console.warn('Translation not found for key:', key)
    translatedMessage = key
  }

  return translatedMessage
}
