
export interface INavItem {
  
  text: string;
  icon: string;
  path?: string;
  expanded?: boolean,
  title?: string;
  visible?: boolean;
  disabled?: boolean
  premiumFeature?: boolean;
  items?: Array<{
    text: string;
    path: string;
    title?: string;
  }>;
  
}

export const navigationTreeSeed: INavItem[]  = [
  {
    text: "menu_sites",
    icon: "info",
    path: "/sites",
    title: "menu_sites",

  },
  {
    text: "menu_geolocation",
    path: "/geolocation",
    icon: "map"
  },
  {
    text: "menu_reports",
    items: [
      {
        text: "menu_monthly_report",
        path: "/informes",
        title: "menu_monthly_report",
      }
    ],
    icon: "doc"
  },
  {
    text: "menu_settings",
    path: "/settings",
    icon: "preferences",
  },
  {
    text: "menu_energy_efficiency",
    icon: "chart",
    premiumFeature: true,
    items: [
      {
        text: "menu_surplus_management",
        path: "/excedents-management",
        title: "menu_surplus_management",
      },
      {
        text: "menu_smart_billing",
        path: "/smart-billing",
        title: "menu_smart_billing",
      },
      {
        text: "menu_EE_algorithms",
        path: "/algorithms-ee",
        title: "menu_EE_algorithms",
      },
      {
        text: "menu_load_activation",
        path: "/load-activation",
        title: "menu_load_activation",
      }
    ]
  },
  {
    text: "menu_energy_groups",
    path: "/energy-groups",
    icon: "group",
    premiumFeature: true,
  },
  {
    text: "menu_electric_mobility", 
    icon: "car",
    premiumFeature: true,
    items: [
      {
        text: "menu_electric_vehicle",
        path: "/vehicle-config-charger",
        title: "menu_vehicle",
      },
      {
        text: "menu_ev_charging",
        path: "/vehicle-battery",
        title: "menu_battery",
      }

    ]
  },
  {
    text: "menu_climate_control",
    path: "/climatization",
    icon: "repeat",
    premiumFeature: true,
  },
  {
    text: "menu_home_automation",
    path: "/domotica",
    icon: "home",
    premiumFeature: true,
  },
  {
    text: "menu_data_export",
    path: "/export-data",
    icon: "export",
    premiumFeature: true,
  },
  {
    text: "menu_privacypolicy",
    path: "/privacypolicy",
    icon: "textdocument",
    premiumFeature: true,
  },

];
export default navigationTreeSeed;
