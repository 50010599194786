<template>
  <div id="root">
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :is-x-small="screen.getScreenSizeInfo?.isXSmall||false"
        :is-large="screen.getScreenSizeInfo?.isLarge||false"
      >
      <div class="content">
        <router-view></router-view>
      </div>
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
  defineExpose
} from "vue";
import { getSizes, subscribe, unsubscribe } from "./utils/media-query";

interface IScreenSizeInfo {
    isXSmall: boolean;
    isLarge: boolean;
    cssClasses: string[];
}
const getScreenSizeInfo = (): IScreenSizeInfo => {
  const screenSizes = getSizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl])
  };
}
const title: string = '';
const screen:{ getScreenSizeInfo: IScreenSizeInfo|null } = reactive({ 
  getScreenSizeInfo: null 
});
screen.getScreenSizeInfo = getScreenSizeInfo();

function screenSizeChanged () {
  screen.getScreenSizeInfo = getScreenSizeInfo();
}

onMounted(() => {
  subscribe(screenSizeChanged);
});

onBeforeUnmount(() => {
  unsubscribe(screenSizeChanged);
});

const cssClasses = computed(() => {
  return ["app"].concat(screen.getScreenSizeInfo?.cssClasses||[]);
});

defineExpose( {
  title,
  screen,
  cssClasses
});

</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}
</style>

