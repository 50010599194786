<template>
  <div height="100%" width="100%" class="single-card">
    <img :src=logo class="imagen_logo" />

    <div class="dx-card dx-card_gecko">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * This layout is used to display a single card with a logo on top
 * A page to show a simple text without any other elements
 * No app toolbar, etc
 */


//Default login logo
let logo = require('@/assets/Infitech_logo.png');

// Login logo personalization by domain
// Riello domain personalization
if (window.location.hostname.includes("riellobess.")) {
  logo = require('@/assets/logo_riello.png');
}

</script>

<style lang="scss">
@import "@/themes/generated/variables.base.scss";

.single-card {
  width: 100%;
  height: 100%;
  position: relative;

  .imagen_logo {
    position: absolute;
    top: 12vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 210px;
    z-index: 1;
    .screen-x-small & {
        top: 10vh;
    }
  }
}

.dx-card_gecko {
    width: 80% !important;
    top: 10vh !important;
    padding: 30px !important;
    .screen-x-small & {
        width: 98% !important;
        padding: 10px !important;
    }
}

.dx-card_gecko .content {
  padding: 10px;
}



</style>
