import { DataResponse } from "@/types/userSessionTypes";

export function errorMapper(err: any): DataResponse<void>{
  // The request was made and the server responded with auth error
  if(err.response?.status == 401){
    return {
      isOk: false,
      errorCode: 'unauthenticated',
      message: 'Unauthenticated',
    }
  }
  
  if(err.response?.status == 400){
    if(err.response.data?.code == 'invalid_argument'){
      return {
        isOk: false,
        errorCode: 'invalid_argument',
        message: err.response.data.msg,
      }
    }else if(err.response.data?.code == 'malformed'){
      return {
        isOk: false,
        errorCode: 'malformed',
        message: err.response.data.msg,
      }
    }
    return {
      isOk: false,
      errorCode: 'bad_request',
      message: 'Bad Request',
    }
  }
  if(err.response?.status == 403){
    return {
      isOk: false,
      errorCode: 'unauthorized',
      message: 'unauthorized',
    }
  }
  if(err.response?.status == 404){
    return {
      isOk: false,
      errorCode: 'not_found',
      message: 'Not found',
    }
  }

  if (err.response?.data?.msg) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx, response is expected to be a TwirpError/GeckoError
    return {
      isOk: false,
      message: err.response.data.msg,
    };
  }
  

  if(err.response?.status){
    // The request was made and server returned an error without a TwirpError/GeckoError
    return {
      isOk: false,
      errorCode: 'unknown',
      message: `Server responded with http ${err.response.status} - ${err.message}`,
    }
  }

  if(err.code == 'ECONNREFUSED' || err.code == 'ETIMEOUT'){
    // The request was made but no response was received, error.request is an instance of http.ClientRequest
    return { 
      isOk: false,
      errorCode: 'network',
      message: `Cannot stablish a connection with the server: ${err.code}`,
    };
  }

  return {
    isOk: false,
    errorCode: 'unknown',
    message: err.message,
  };
}
